<script>
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  import { goto } from "$app/navigation";
  import { getContext } from "svelte";
  import { Button, SearchPanel } from "@ObamaFoundation/of-design-system";
  import { transformSuggestions, transformHit } from "$lib/utilities/search";
  import WithAlgolia from "./WithAlgolia.svelte";

  /** @type {{fullTextSearch: boolean, querySuggestions: boolean}} */
  const featureFlags = getContext("featureFlags") ?? {};

  export let currentTheme;
  export let isSearchPanelVisible = false;

  let isLoading = false;
  let updateQuery;
  let updateSuggestion;
  let prevInputValue = "";
  let results = [];
  let suggestions = [];

  let selectedValue = "";

  $: searchQuerySuggestionsProps =
    featureFlags.querySuggestions && results.length && suggestions?.length
      ? {
          id: SEARCH_SUGGESTIONS_ID,
          ...searchQuerySuggestionsProps,
          items: transformSuggestions(suggestions?.slice(0, 3))
        }
      : null;

  const SEARCH_PANEL_ID = "searchPanel";
  const SEARCH_SUGGESTIONS_ID = "search-suggestions";

  const handleSearchClose = () => {
    isSearchPanelVisible = false;
  };

  const clearResults = () => {
    results = [];
    searchQuerySuggestionsProps = null;
  };

  const handleSearchClear = () => {
    prevInputValue = "";
    selectedValue = "";
    results = [];
    searchQuerySuggestionsProps = null;
  };

  const handleOnSearchInput = (e) => {
    const input = e.target.value;
    const isIncreasing = prevInputValue.length < input.length;

    if (input.length > 2) {
      if (input.length === 3 && isIncreasing) {
        isLoading = true;
      }
      if (updateQuery) {
        updateQuery(input);
        if (e.source !== "itemSelect") {
          updateSuggestion(input);
        }
      }
    } else {
      clearResults();
    }
    prevInputValue = input;
  };

  const handleSeeAll = async () => {
    if (!(results?.length > 3)) {
      return;
    }

    const onResultsPage = $page.url.pathname === "/search/" && $page.url.searchParams.get("q");
    if (!onResultsPage) {
      handleSearchClose();
    }

    await goto(`/search?q=${prevInputValue}`, {
      replaceState: true,
      invalidateAll: true,
      state: {
        query: prevInputValue,
        results
      }
    });

    /**
     * because of the static nature of our site, query param change doesn't trigger page reload
     * trigger manual reload to render search results for new query
     * */
    if (onResultsPage) {
      window.location.reload();
    }
  };

  const handleQuerySuggestionItemSelect = (e) => {
    selectedValue = e.detail;
    handleOnSearchInput({ target: { value: selectedValue }, source: "itemSelect" });
    searchQuerySuggestionsProps.query, (searchQuerySuggestionsProps.selectedItem = selectedValue);
  };

  $: resultsTitle =
    results.length >= 3
      ? "Top 3 results"
      : results.length < 3 && results.length > 0
        ? `Top ${results.length} results`
        : "";
  $: errorMessage = prevInputValue.length >= 3 && results.length === 0 ? "No matching results" : "";
  $: showSeeAllButton = results.length > 3;
</script>

{#if isSearchPanelVisible}
  <WithAlgolia
    bind:isLoading
    bind:updateQuery
    bind:updateSuggestion
    bind:results
    bind:suggestions
    hitRendererSelector={`#${SEARCH_PANEL_ID} ul > li > a`}
    suggestionRendererSelector={`#${SEARCH_SUGGESTIONS_ID} > li > button`}
    {transformHit}
  >
    <SearchPanel
      id={SEARCH_PANEL_ID}
      focusOnMount
      on:submit={handleSeeAll}
      on:clear={handleSearchClear}
      on:input={handleOnSearchInput}
      on:change
      on:querySuggestionItemSelect={handleQuerySuggestionItemSelect}
      theme={currentTheme}
      results={results.slice(0, 3)}
      {errorMessage}
      {isLoading}
      {resultsTitle}
      {selectedValue}
      {searchQuerySuggestionsProps}
    >
      <svelte:fragment slot="button">
        {#if showSeeAllButton}
          <Button
            variant="outline"
            label="See all results"
            theme="white"
            buttonClass="mt-5 xl:mt-7.5 w-full md:w-auto justify-center"
            on:click={handleSeeAll}
          />
        {/if}
      </svelte:fragment>
    </SearchPanel>
  </WithAlgolia>
{/if}
